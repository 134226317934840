/* eslint-disable no-useless-constructor */
/* eslint-disable no-alert */

import feathersClient, { BaseModel, makeServicePlugin } from "../feathers-client";

class Users extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "Users";
}

const servicePlugin = makeServicePlugin({
  Model: Users,
  service: feathersClient.service("users"),
});

export default servicePlugin;
