import Vue from "vue";
import Router from "vue-router";
import store from "@/store";

Vue.use(Router);

function paramToInt(route) {
  const obj = route.params;
  Object.keys(route.params).forEach((e) => {
    const num = Number.parseInt(route.params[e], 10);
    if (!Number.isNaN(num)) obj[e] = num;
  });
  return obj;
}

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
    },
    {
      path: "/wiki",
      name: "Wiki",
      meta: { requiresAuth: true },
      beforeEnter: () => {
        const url = `${window.location.protocol}//${window.location.host}/wiki/`;
        window.open(url, "_blank");
      },
    },
    {
      name: "Jobs",
      path: "/jobs",
      meta: { requiresAuth: true },
      component: () => import(/* webpackChunkName: "Deltas" */ "@/views/JobOverview.vue"),
    },
    {
      name: "Deltas",
      path: "/deltas",
      meta: { requiresAuth: true },
      component: () => import(/* webpackChunkName: "Deltas" */ "@/views/Deltas.vue"),
    },
    {
      name: "Forecasts",
      path: "/forecasts",
      meta: { requiresAuth: true },
      component: () => import(/* webpackChunkName: "IHR" */ "@/views/Forecasts.vue"),
    },
    {
      path: "/scenario",
      alias: "",
      props: paramToInt,
      meta: { requiresAuth: true },
      component: () => import(/* webpackChunkName: "Scenarios" */ "@/views/Scenario.vue"),
      children: [
        {
          path: "",
          name: "Scenarios",
          props: paramToInt,
          meta: { requiresAuth: true, gotoScenarioOverview: true },
          component: () =>
            import(/* webpackChunkName: "Scenarios" */ "@/views/ScenarioOverview.vue"),
        },
        {
          path: ":id",
          name: "ScenarioCases",
          props: paramToInt,
          meta: { requiresAuth: true, gotoScenarioOverview: true },
          component: () => import(/* webpackChunkName: "Scenarios" */ "@/views/ScenarioCases.vue"),
        },
      ],
    },
    {
      path: "/scenario/:scenario/case/:id",
      alias: "/case/:id",
      props: paramToInt,
      component: () => import(/* webpackChunkName: "Cases" */ "@/views/CaseView.vue"),
      children: [
        {
          name: "CaseInput",
          path: "input",
          alias: "",
          meta: { requiresAuth: true },
          component: () => import(/* webpackChunkName: "Cases" */ "@/views/CaseInput.vue"),
        },
        {
          name: "CaseOutput",
          path: "output",
          meta: { requiresAuth: true },
          component: () => import(/* webpackChunkName: "Cases" */ "@/views/CaseOutput.vue"),
        },
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (!to.matched.some((record) => record.meta.requiresAuth)) {
    next();
    return;
  }

  let payload;
  if (to.query.token != null) {
    payload = {
      strategy: "auth0",
      accessToken: to.query.token,
    };
  }

  store
    .dispatch("auth/authenticate", payload)
    .then(() => {
      next();
    })
    .catch(() => {
      next({
        name: "login",
        replace: true,
        query: {
          redirect: to.fullPath,
        },
      });
    });
});

export default router;
