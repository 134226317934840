import Vue from "vue";
import App from "@/App";
import router from "@/router";
import store from "@/store";
import Utils from "@/plugins/utils";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

Vue.use(Utils);
