import feathers from "@feathersjs/feathers";
import rest from "@feathersjs/rest-client";
import auth from "@feathersjs/authentication-client";
import { batchClient } from "feathers-batch";
import feathersVuex from "feathers-vuex";

import getUrl from "@/plugins/getUrl";

const restClient = rest(getUrl()).fetch(window.fetch);

const transport = restClient;
const feathersClient = feathers()
  .configure(transport)
  .configure(
    auth({
      jwtStrategy: "auth0",
      storage: window.localStorage,
      // storage: new CookieStorage({
      //   ...(process.env.NODE_ENV === "production" ? { domain: "acelerex.com" } : {}),
      //   sameSite: "lax",
      // }),
    })
  )
  .configure(batchClient({ batchService: "batch", exclude: ["authentication"] }));

export default feathersClient;

const { BaseModel, makeServicePlugin, makeAuthPlugin, FeathersVuex } = feathersVuex(
  feathersClient,
  { serverAlias: "api" }
);

export { makeAuthPlugin, makeServicePlugin, BaseModel, FeathersVuex };
