/* eslint-disable no-useless-constructor */
/* eslint-disable no-alert */

import feathersClient, { BaseModel, makeServicePlugin } from "../feathers-client";

class Profile extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "Profiles";
}

const servicePlugin = makeServicePlugin({
  Model: Profile,
  service: feathersClient.service("profiles"),
  instanceDefaults(data) {
    const profile = data;
    profile.value = typeof profile.value === "string" ? JSON.parse(profile.value) : profile.value;

    return profile;
  },
  getters: {
    listByType(state, getters) {
      return (type) =>
        getters.find({
          paginate: false,
          query: {
            type,
          },
        }).data;
    },
  },
});

export default servicePlugin;
