/* eslint-disable no-useless-constructor */
/* eslint-disable no-alert */
import feathersClient, { BaseModel, makeServicePlugin } from "../feathers-client";

class Batch extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "Batch";
}

const servicePlugin = makeServicePlugin({
  Model: Batch,
  service: feathersClient.service("batch"),
});

export default servicePlugin;
