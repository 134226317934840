/* eslint-disable no-useless-constructor */
/* eslint-disable no-alert */

import feathersClient, { BaseModel, makeServicePlugin } from "../feathers-client";

class Outputs extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "Outputs";
}

const servicePlugin = makeServicePlugin({
  Model: Outputs,
  service: feathersClient.service("outputs"),
  getters: {
    loadPending(state) {
      return state.isGetPending || state.isFindPending;
    },
  },
});

export default servicePlugin;
