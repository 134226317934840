/* eslint-disable no-useless-constructor */
/* eslint-disable no-alert */

import download from "downloadjs";
import getUrl from "@/plugins/getUrl";
import feathersClient, { BaseModel, makeServicePlugin } from "../feathers-client";

class Scenarios extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "Scenarios";
}

const servicePlugin = makeServicePlugin({
  Model: Scenarios,
  service: feathersClient.service("scenarios"),
  state: {
    currentId: null,
  },
  getters: {
    loadPending(state) {
      return state.isGetPending || state.isFindPending;
    },
  },
  mutations: {
    setCurrent(state, id) {
      state.currentId = id;
    },
    clearCurrent(state) {
      state.currentId = null;
    },
  },
  actions: {
    downloadOutputs({ getters, rootState }, id) {
      const scenarioId = id;
      const scenarioName = getters.get(scenarioId).name;

      // Note: Feathers-client doesn't handle file downloads too well...
      const headers = {
        Authorization: rootState.auth.accessToken,
      };
      return fetch(`${getUrl()}/scenarios/${scenarioId}?download=true`, {
        headers,
      })
        .then((res) => {
          console.debug(res);
          return res.blob();
        })
        .then((blob) => {
          console.debug(blob);
          return download(blob, `${scenarioName}.zip`, "application/zip");
        });
    },
    confirmRemove({ dispatch }, id) {
      const itemId = id;
      if (
        // eslint-disable-next-line no-alert
        window.confirm("Are you sure you want to delete this scenario?")
      ) {
        return dispatch("remove", itemId);
      }
      return false;
    },
  },
});

export default servicePlugin;
