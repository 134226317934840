<template>
  <v-app>
    <v-app-bar app flat clipped-left dense>
      <v-menu v-if="isAuthenticated" transition="slide-y-transition" offset-y bottom>
        <template #activator="{ on }">
          <v-app-bar-nav-icon class="hidden-sm-and-up" v-on="on"> </v-app-bar-nav-icon>
        </template>
        <v-list>
          <v-list-item v-for="item in navMenu" :key="item.title" :to="item.to">
            <v-list-item-content>
              <v-list-item-title
                >{{ item.title
                }}<v-chip v-if="item.chip" color="accent">
                  {{ item.chip }}
                </v-chip></v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-toolbar-title>
        <a href="http://acelerex.com">
          <img height="48" src="@/assets/acelerex-logo.png" />
        </a>
      </v-toolbar-title>
      <v-spacer />
      <template v-if="isAuthenticated">
        <v-toolbar-items class="hidden-xs-only">
          <v-btn v-for="item in navMenu" :key="item.title" :to="item.to" exact depressed ripple
            >{{ item.title }}
            <v-chip v-if="item.chip" color="accent">
              {{ item.chip }}
            </v-chip>
          </v-btn>
        </v-toolbar-items>
        <v-menu transition="slide-y-transition" offset-y bottom>
          <template #activator="{ on }">
            <v-btn v-on="on" icon>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-icon v-on="on">account_circle</v-icon>
                </template>
                <span>{{ getUsername }}</span>
              </v-tooltip>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-action>
                <v-switch :value="$vuetify.theme.dark" inset></v-switch>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $vuetify.theme.dark ? "Night Mode" : "Day Mode" }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="logout">
              <v-list-item-action>
                <v-icon>exit_to_app</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Log Out</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
    <v-footer app padless color="secondary">
      <v-row justify="center">
        <v-col class="text-center white--text" cols="12">
          <strong>Acelerex v{{ version }}</strong> &mdash; &copy;2017-{{ currentYear }}
          Acelerex Inc.
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "App",
  components: {},
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
    };
  },
  computed: {
    ...mapState({
      getUsername: (state) => (state.auth.user ? state.auth.user.email : null),
      currentScenarioId: (state) => state.scenarios.currentId,
    }),
    ...mapGetters({
      isAuthenticated: "isAuthenticated",
    }),
    currentYear() {
      return new Date().getFullYear();
    },
    navMenu() {
      return [
        {
          title: "Scenarios",
          to: this.scenarioOverview,
        },
        {
          title: "Deltas",
          to: { name: "Deltas" },
        },
        (() => {
          // TODO: Use a real feature flag:
          // See https://docs.gitlab.com/ee/operations/feature_flags.html
          // and https://github.com/Unleash/unleash
          const { hostname } = window.location;
          return hostname.includes("consulting") || hostname.includes("localhost")
            ? {
                title: "Forecasts",
                to: { name: "Forecasts" },
              }
            : {};
        })(),
        {
          title: "Wiki",
          to: { name: "Wiki" },
        },
        {
          title: "Jobs",
          to: { name: "Jobs" },
        },
      ].filter((e) => e.title != null);
    },
    scenarioOverview() {
      let route = {
        name: "Scenarios",
      };
      if (!this.$route.meta.gotoScenarioOverview && this.currentScenarioId) {
        route = {
          name: "ScenarioCases",
          params: { id: this.currentScenarioId },
        };
      }
      return route;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.go({ name: "login" });
      });
    },
  },
};
</script>

<style>
.flex > .v-subheader {
  height: auto;
}

.v-data-table table tbody tr.v-data-table__expanded__content {
  box-shadow: none;
  -webkit-box-shadow: none;
}
</style>
