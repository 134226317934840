/* eslint-disable no-useless-constructor */
/* eslint-disable no-alert */

import { CaseModel } from "data-dictionary";
import download from "downloadjs";
import getUrl from "@/plugins/getUrl";
import feathersClient, { BaseModel, makeServicePlugin } from "../feathers-client";

class Cases extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "Cases";
}

const servicePlugin = makeServicePlugin({
  Model: Cases,
  service: feathersClient.service("cases"),
  instanceDefaults(data) {
    // eslint-disable-next-line no-param-reassign
    data.settings = typeof data.settings === "string" ? JSON.parse(data.settings) : data.settings;

    const Case = new CaseModel(data);
    // console.debug('instanceDefault', data, Case);
    return Case;
  },
  preferUpdate: true,
  state: {},
  getters: {
    loadPending(state) {
      return state.isGetPending || state.isFindPending;
    },
    savePending(state) {
      return state.isUpdatePending || state.isPatchPending;
    },
  },
  actions: {
    downloadOutputs({ getters, rootState, rootGetters }, id = null) {
      const caseId = id;
      // eslint-disable-next-line no-underscore-dangle
      const _case = getters.get(caseId);
      const scenario = rootGetters["scenarios/get"](_case.scenario_id);
      const scenarioName = scenario.name;

      // Note: Feathers-client doesn't handle file downloads too well...
      const headers = {
        Authorization: rootState.auth.accessToken,
      };
      return fetch(`${getUrl()}/cases/${caseId}?download=true`, {
        headers,
      })
        .then((res) => {
          console.debug(res);
          return res.blob();
        })
        .then((blob) => {
          console.debug(blob);
          return download(blob, `${scenarioName}.zip`, "application/zip");
        });
    },
    confirmRemove({ dispatch }, id) {
      const itemId = id;
      // eslint-disable-next-line no-alert
      if (window.confirm("Are you sure you want to delete this case?")) {
        return dispatch("remove", itemId);
      }
      return false;
    },
  },
});

export default servicePlugin;
